import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Columns, Section } from "../components/Containers"
import { Image, Text } from "../components/Core"
import Seamless from "../components/Seamless"

const TinderSpecialOffer = () => (
  <Layout language="en">
    <SEO title="Tinder Special Offer" description="" />

    <Section colorBack zeroBottomMobile>
      <div style={{ paddingTop: 100 }}>
        <Columns sideColumnsSize={4} className="is-vcentered">
          <div className="column px-40--mobile">
            <h1 className="has-text-centered">
              Free consults for a limited time only!
            </h1>
          </div>
          <div className="column is-2"></div>
          <div className="column mt-3--mobile">
            <Image publicId="MAXA/DEV/homepage-practice" />
          </div>
        </Columns>
      </div>
    </Section>

    <Section>
      <Columns sideColumnsSize={4}>
        <div className="column px-40--mobile">
          <Text
            text={`## Achieve Your Desired Size\n\nAt MAXA Male Enhancement, we understand the importance of feeling confident in all aspects of life. Whether you are seeking an enhancement for personal reasons or looking to improve sexual performance by boosting your self-esteem and addressing any performance anxiety, Dr. Doe, an esteemed medical professional, is here to guide you through this transformative process.\n\nSchedule a consult with Dr. Doe today for 50% off our standard fee.`}
          />
          <a
            href="/contact-us/"
            className="standard-button contained mx-auto--mobile">
            Contact Us
          </a>
        </div>
      </Columns>
    </Section>
  </Layout>
)

export default TinderSpecialOffer
